import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo, BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import Pagination from 'components/Pagination';
import { ProductPreview } from 'components/Product';
import useSiteMetadata from 'hooks/useSiteMetadata';
import trackEvent from 'utils/trackEvent';

import * as styles from './collection.module.css';

export const query = graphql`
  query CollectionQuery($handle: String!, $productIds: [String!]) {
    shopifyCollection(handle: { eq: $handle }) {
      title
      handle
    }
    allShopifyProduct(filter: { id: { in: $productIds } }) {
      edges {
        node {
          id
          title
          handle
          vendor
          shopifyId
          featuredMedia {
            id
            preview {
              image {
                altText
                gatsbyImageData(aspectRatio: 1, width: 400)
              }
            }
          }
          tags
          variants {
            id
            shopifyId
            price
            compareAtPrice
            availableForSale
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export default function Collection({ data, pageContext, location }) {
  const { siteUrl } = useSiteMetadata();
  const collection = get(data, 'shopifyCollection', false);
  const products = get(data, 'allShopifyProduct.edges', []);

  const pageInfo = {
    pageCount: pageContext.numPages,
    hasPreviousPage: pageContext.currentPage !== 1,
    hasNextPage: pageContext.currentPage < pageContext.numPages,
    currentPage: pageContext.currentPage,
  };

  const { productIds } = pageContext;
  const sortByProductIds = (a, b) =>
    productIds.indexOf(a.node.id) - productIds.indexOf(b.node.id);
  products.sort(sortByProductIds);

  if (!collection) return null;

  const canonical = `${siteUrl}${location.pathname}`;

  useEffect(() => {
    trackEvent('impression', { products, collection });
  }, []);

  return (
    <Layout location={location}>
      <GatsbySeo
        title={collection.title}
        description={collection.bodySummary}
        canonical={canonical}
        openGraph={{
          title: `${collection.title} - Page ${pageInfo.currentPage}`,
          description: collection.description,
          url: canonical,
          type: 'product.group',
        }}
      />

      <BreadcrumbJsonLd
        name="Collection"
        itemListElements={[
          {
            position: 1,
            name: collection.title,
            item: canonical,
          },
        ]}
      />

      <div className={styles.container}>
        <h1 className={styles.title}>{collection.title}</h1>

        <ul className={styles.list}>
          {products.map((product, index) => (
            <li key={product.node.id}>
              <ProductPreview
                listName={`Collection Page ${collection.title}`}
                path={collection.handle}
                product={product.node}
                index={index}
              />
            </li>
          ))}
        </ul>
      </div>
      <Pagination
        path={`/collections/${collection.handle}`}
        pageInfo={pageInfo}
      />
    </Layout>
  );
}
